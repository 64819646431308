import React from "react";
import { useSearch } from "../../context/search";

import { useNavigate } from "react-router-dom";
import Button from "../Button";
import axiosInstance from "../../utils/axiosConfig";
const SearchInput = () => {
  const [values, setValues] = useSearch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.get(
        `/product/search/${values.keyword}`
      );
      setValues({ ...values, results: data });
      navigate("/search");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="mt-[80px] lg:mt-0 justify-center align-middle">
      <form
        className="d-flex search-form"
        role="search"
        onSubmit={handleSubmit}
      >
        <input
          className="w-[300px] p-2 me-2"
          type="search"
          placeholder="Recherche"
          aria-label="Search"
          value={values.keyword}
          onChange={(e) => setValues({ ...values, keyword: e.target.value })}
        />
        <Button className="max-lg:p-3" type="submit">
          Search
        </Button>
      </form>
    </div>
  );
};

export default SearchInput;
