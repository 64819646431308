import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "../../components/Layout/Layout";

import { useAuth } from "../../context/auth";
import moment from "moment";
import axiosInstance from "../../utils/axiosConfig";

const ClientOrders = () => {
  const [orders, setOrders] = useState([]);
  const [auth, setAuth] = useAuth();
  const getOrders = async () => {
    try {
      const { data } = await axiosInstance.get("/auth/orders");
      setOrders(data);
      //console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);
  return (
    <Layout title={"Your Orders"}>
      <div className="main-container">
        <div className="col-md-3">
          <UserMenu />
        </div>
        <div className="table-container">
          <h1 className="text-center">My Orders</h1>
          {orders?.map((o, i) => {
            return (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Buyer</th>
                    <th scope="col">Date</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Pay-CCP</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <div className="mb-3">
                            <img
                              src={`https://gcosmosweb-server.vercel.app/api/v1/product/product-photo/${p._id}`}
                              className="/"
                              alt={p.name}
                              width="50px"
                              height={"auto"}
                            />
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <p>{p.name}</p>
                        </div>
                      ))}
                    </td>
                    <td>{o?.status}</td>
                    <td>{o?.buyer?.name}</td>
                    <td>{moment(o?.createdAt).fromNow()}</td>
                    <td>{o?.payment.success ? "Réussi" : "Non"}</td>
                    <td>{o?.payment2}</td>
                    <td>{o?.products?.length}</td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <p>{p.price}</p>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default ClientOrders;
