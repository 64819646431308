import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/CategoryProductStyles.css";

import Section from "../components/Section";
import Footer from "../components/Footer";
import Button from "../components/Button";
import axiosInstance from "../utils/axiosConfig";
import { useCart } from "../context/cart";
import toast from "react-hot-toast";
import { TiArrowBack } from "react-icons/ti";
import { FaShareAlt } from "react-icons/fa";
import ButtonGradient from "../assets/svg/ButtonGradient";
const CategoryProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    if (params?.slug) getPrductsByCat();
  }, [params?.slug]);
  const getPrductsByCat = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/product/product-category/${params.slug}`
      );
      setProducts(data?.products);
      setCategory(data?.category);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle back button click
  const handleBack = () => {
    navigate(-1);
  };

  // Handle share button click
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link copied successfully");
    } catch (error) {
      toast.error("Failed to copy the link");
    }
  };

  return (
    <Layout title={`${category?.name} Products`}>
      <Section crosses>
        <div className="container pro-nav-bar max-lg:mt-[80px] lg:mb-7 max-lg:mb-[-20px]">
          <Button className="text-[22px] p-3" onClick={handleBack}>
            <TiArrowBack />
          </Button>
          <Button white className="text-[20px] p-3" onClick={handleShare}>
            <FaShareAlt />
          </Button>
        </div>
        <div className="container category max-lg:mt-[80px]">
          <Button white className="text-[18px] mb-3 ml-3">
            {" "}
            Category : {category?.name}
          </Button>
          <h6 className="text-center">{products?.length} results found </h6>
          <div className="row">
            <div className="col-md-9 offset-1">
              <div className="d-flex flex-wrap">
                {products?.map((p) => (
                  <div className="card  m-2" key={p._id}>
                    <img
                      src={`https://gcosmosweb-server.vercel.app/api/v1/product/product-photo/${p._id}`}
                      className="card-img-top"
                      alt={p.name}
                    />
                    <div className="card-body">
                      <div className="card-name-price">
                        <h5 className="card-title">{p.name}</h5>
                        <h5 className="card-title card-price">
                          {p.price.toLocaleString("en-DZ", {
                            style: "currency",
                            currency: "DZD",
                          })}
                        </h5>
                      </div>
                      <p className="card-text ">
                        {p.description.substring(0, 60)}...
                      </p>
                      <div className="card-name-price">
                        <Button
                          className="btn-absolut"
                          onClick={() => navigate(`/product/${p.slug}`)}
                        >
                          More Details
                        </Button>
                        {/*  <Button
                          className=""
                          onClick={() => {
                            setCart([...cart, p]);
                            localStorage.setItem(
                              "cart",
                              JSON.stringify([...cart, p])
                            );
                            toast.success("Item Added to cart");
                          }}
                        >
                          ADD TO CART
                        </Button>  */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="m-2 p-3">
            {products && products.length < total && (
              <button
                className="btn btn-warning"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(page + 1);
                }}
              >
                {loading ? "Loading ..." : "Loadmore"}
              </button>
            )}
          </div> */}
            </div>
          </div>
        </div>
      </Section>
      <ButtonGradient />
      <Footer />
    </Layout>
  );
};

export default CategoryProduct;
