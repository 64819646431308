import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { Select } from "antd";
import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";
const { Option } = Select;

const AdminOrders = () => {
  const [status] = useState([
    "New⏱️",
    "25% Done✅",
    "50% Done✅",
    "75% Done✅",
    "100% Done🚀",
    "Canceled❌",
  ]);
  const [payment2] = useState(["Not Paid❌", "Pending🕒", "Paid✅"]);

  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();
  const getOrders = async () => {
    try {
      const { data } = await axiosInstance.get("/auth/all-orders");
      setOrders(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  const handleChange = async (orderId, value) => {
    try {
      const { data } = await axiosInstance.put(
        `/auth/order-status/${orderId}`,
        {
          status: value,
        }
      );
      getOrders();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentChange = async (orderId, value) => {
    try {
      const { data } = await axiosInstance.put(
        `/auth/order-payment/${orderId}`,
        {
          payment2: value,
        }
      );

      const orderDetailsResponse = await axiosInstance.get(
        `/auth/order/${orderId}`
      );
      const orderDetails = orderDetailsResponse.data.order;
      //console.log("Order Details:", orderDetails); // Log order details for debugging
      if (
        !orderDetails ||
        !orderDetails.products ||
        orderDetails.products.length === 0
      ) {
        console.error("Invalid order details:", orderDetails);
        return;
      }

      // Fetch the price of each product and calculate the total price

      // Update user wallet if the payment status is changed to "Payé"

      getOrders(); // Refresh orders after payment status update
    } catch (error) {
      console.error("Error handling payment change:", error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const confirmPayment = window.confirm(
        "Êtes-vous sûr de vouloir supprimer la commande?"
      );
      if (!confirmPayment) {
        return; // Cancel the function if not confirmed
      }
      await axiosInstance.delete(`/auth/order/${orderId}`);
      toast.success("Commande supprimée avec succès");
      getOrders();
    } catch (error) {
      console.log(error);
      toast.error("Échec de la suppression de la Commande");
    }
  };

  return (
    <Layout title={"All Orders Data"}>
      <div className="main-container">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="table-container">
          <h1 className="text-center ">Toutes les Commandes</h1>
          {orders?.map((o, i) => {
            return (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Img</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Status</th>
                    <th scope="col">Buyer</th>
                    <th scope="col">Date</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Pay-CCP</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <div className="mb-3">
                            <img
                              src={`https://gcosmosweb-server.vercel.app/api/v1/product/product-photo/${p._id}`}
                              alt={p.name}
                              width="50px"
                              height={"auto"}
                            />
                          </div>
                        </div>
                      ))}
                    </td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <p>{p.name}</p>
                        </div>
                      ))}
                    </td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <p>{p.description.substring(0, 30)}</p>
                        </div>
                      ))}
                    </td>
                    <td>
                      <Select
                        bordered={false}
                        onChange={(value) => handleChange(o._id, value)}
                        defaultValue={o?.status}
                        className="bg-[#17da1a] rounded"
                      >
                        {status.map((s, i) => (
                          <Option key={i} value={s}>
                            {s}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td>{o?.buyer?.name}</td>
                    <td>{moment(o?.createdAt).fromNow()}</td>
                    <td>{o?.payment.success ? "Réussi" : "Non"}</td>
                    <td>
                      <Select
                        bordered={false}
                        onChange={(value) => handlePaymentChange(o._id, value)}
                        defaultValue={o?.payment2}
                        className="bg-[#17da1a] rounded"
                      >
                        {payment2.map((s, i) => (
                          <Option key={i} value={s}>
                            {s}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td>{o?.products?.length}</td>
                    <td>
                      {o?.products?.map((p, i) => (
                        <div className="flex-row" key={p._id}>
                          <p>{p.price}</p>
                        </div>
                      ))}
                    </td>
                    <td>
                      <Button
                        className="btn"
                        onClick={() => handleDeleteOrder(o._id)}
                      >
                        Delete❌
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default AdminOrders;
