import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/auth";

const UserMenu = () => {
  const [auth] = useContext(AuthContext);
  const role = auth.user ? auth.user.role : null;

  return (
    <div className="admin-menu">
      <div className="dashboard-menu">
        <NavLink
          to={`/dashboard/${getDashboardRoute(role)}/`}
          className="menu-item"
        >
          Dashboard
        </NavLink>
        <NavLink
          to={`/dashboard/${getDashboardRoute(role)}/orders`}
          className="menu-item"
        >
          Ordres
        </NavLink>
        {role === 2 && (
          <NavLink
            to={`/dashboard/${getDashboardRoute(role)}/orders`}
            className="menu-item"
          >
            Ordres
          </NavLink>
        )}
        {role === 5 && (
          <NavLink
            to={`/dashboard/${getDashboardRoute(role)}/transactions`}
            className="menu-item"
          >
            Transactions
          </NavLink>
        )}
        <NavLink
          to={`/dashboard/${getDashboardRoute(role)}/requests`}
          className="menu-item"
        >
          Requests
        </NavLink>
      </div>
    </div>
  );
};

const getDashboardRoute = (role) => {
  switch (role) {
    case 940:
      return "admin";
    case 1:
      return "business";
    case 2:
      return "digitalCreator";
    case 3:
      return "manager";
    case 4:
      return "client";
    default:
      return "client";
  }
};

export default UserMenu;
