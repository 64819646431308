import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";

import Retraits from "./Retrait";
import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";

const Requests = () => {
  //context

  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [description, setDescription] = useState("");
  const [requestType, setRequestType] = useState("");

  const [stillSumbitting, setStillSubmitting] = useState("Send a Request");

  useEffect(() => {
    const fetchData = async () => {
      //console.log(auth?.user);
      const { _id, email, name, phone, address } = auth?.user || {};
      setUser(_id);
      setName(name);
      setPhone(phone);
      setEmail(email);
      setAddress(address);
    };
    fetchData();
  }, [auth?.user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setStillSubmitting("Sending...");

      const dataToSend = {
        user,
        name,
        email,
        phone,
        address,
        description,
        requestType,
      };
      //console.log("thats the data to send", dataToSend);

      const { data } = await axiosInstance.post(
        "/withdrawal/createwithdrawals",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (data?.error) {
        toast.error(data.error);
      } else {
        setDescription("");
        setRequestType("");
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        setStillSubmitting("Send a New Request");
        toast.success("Your request has been sent");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Requests - GCosmosWeb"}>
      <div className="main-container">
        <div className="col-md-3">
          <UserMenu />
        </div>
        <div className="table-container">
          <form
            className="user-form"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
          >
            <h4 className="title mb-3">Make Request</h4>
            <div className="mb-3">
              <input
                type="text"
                value={name}
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Votre Nom"
                autoFocus
                disabled
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                value={email}
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Votre Email "
                disabled
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                value={phone}
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Nr Téléphone"
                disabled
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                value={address}
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Votre Adresse"
                disabled
              />
            </div>

            <div className="mb-3">
              <select
                value={requestType}
                onChange={(e) => setRequestType(e.target.value)}
                className="form-control"
                id="exampleInputEmail1"
              >
                <option value="">Choisissez votre type de demande</option>
                <option value="Remarque">Noticed</option>
                <option value="Paiement">Paiement</option>
                <option value="Autres">Other</option>
              </select>
            </div>

            <div className="mb-3">
              <textarea
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Description"
                style={{ height: "120px" }}
              />
            </div>

            <Button type="submit" className="mb-3">
              {stillSumbitting}
            </Button>
          </form>
        </div>
      </div>

      <div>
        <Retraits />
      </div>
    </Layout>
  );
};

export default Requests;
