import axios from "axios";
import config from "../config";

// Create an axios instance with the base URL
const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true, // Ensure credentials are sent
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
