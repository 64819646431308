import React from "react";
import Layout from "./../components/Layout/Layout";
import about1 from "../assets/about.jpg";
import Footer from "../components/Footer";
import ButtonGradient from "../assets/svg/ButtonGradient";
import { StarsCanvas } from "../components/canvas";
import Heading from "../components/Heading";
import { contact } from "../assets/index";
import Section from "../components/Section";
import Button from "../components/Button";
import ContactForm from "../components/Form/contactForm";
import { Toaster } from "react-hot-toast";
import { socials } from "../constants";
import Benefits from "../components/Benefits";

const Contact = () => {
  return (
    <Layout title="Contact Us - G-Cosmos Web">
      <Section crosses>
        <div className="container relative z-2 pt-[100px]">
          <Heading tag="Get in touch with G-COSMOS WEB" title="Contact Us" />

          <div className="row contactus ">
            <div className="pages-img-div lg:mt-[-120px]">
              <img src={contact} alt="contactus" style={{ width: "100%" }} />
            </div>
            <div className="pages-text-div">
              <p className="body-2 mb-[3rem] text-n-3 contact-text-bg ">
                Ready to elevate your online presence and boost your revenue?
                Get in touch with us today! We're excited to learn about your
                project and show you how we can help you achieve your business
                dreams.
              </p>
            </div>
          </div>
        </div>
        <StarsCanvas />
      </Section>
      <Section crosses>
        <div className="container flex lg:w-[60%]">
          <Toaster />
          <ContactForm />
        </div>
      </Section>
      <Section crosses>
        <div className="container flex">
          <div className="block align-middle content-center">
            <Heading title="Or contact us on" />

            <ul className="flex gap-5 flex-wrap align-middle content-center mb-5">
              {socials.map((item) => (
                <a
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  className="flex items-center justify-center w-16 h-16 bg-n-7 rounded-full transition-colors hover:bg-n-6"
                >
                  <img
                    src={item.iconUrl}
                    width={26}
                    height={26}
                    alt={item.title}
                    className="text-[#00FB04]  "
                  />
                </a>
              ))}
            </ul>
            <h3 className="text-center mt-[20px]">
              <a href="mailto:contact@gcosmosweb.com?body=My custom mail body">
                📧👉 contact@gcosmosweb.com
              </a>
            </h3>
            <h3 className="text-center mt-[20px]">
              <a href="mailto:g.cosmosweb@gmail.com?body=My custom mail body">
                📧👉 g.cosmosweb@gmail.com
              </a>
            </h3>
            <h3 className="text-center mt-[20px]">
              {" "}
              <a href="tel:+380636790425">📞👉+380636790425</a>
            </h3>
            <h3 className="text-center mt-[20px]">
              <a href="tel:+213772283194">📞👉 +213772283194</a>
            </h3>
          </div>
        </div>
      </Section>
      <Footer />
      <ButtonGradient />
    </Layout>
  );
};

export default Contact;
