import React, { useState } from "react";
import Layout from "./../../components/Layout/Layout";

import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import { StarsCanvas } from "../../components/canvas";
import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";

const ForgotPasssword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [answer, setAnswer] = useState("");

  const navigate = useNavigate();

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("/auth/forgot-password", {
        email,
        newPassword,
        answer,
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);

        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Forgot Password - G-COSMOS WEB"}>
      <div
        className="form-container "
        style={{
          minHeight: "100vh",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h4 className="title mb-5">Reset Password</h4>

          <div className="mb-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Enter your email "
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Enter your secret word "
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Enter Your Password"
              required
            />
          </div>

          <Button type="submit" className="btn mt-3">
            RESET ↩️
          </Button>
          <div className="switch-link">
            <Link to="/login">Login here 👉</Link>
          </div>
        </form>
      </div>
      <StarsCanvas />
    </Layout>
  );
};

export default ForgotPasssword;
