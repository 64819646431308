import React from "react";

import Header from "../Header";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
const Layout = ({ children, title, description, keywords, author }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Header />
      <main style={{ minHeight: "90vh" }}>
        <Toaster />

        {children}
      </main>
    </div>
  );
};

Layout.defaultProps = {
  title: "G-COSMOS WEB",
  description:
    "Transformez votre vision numérique en réalité avec G-COSMOS WEB, votre partenaire de confiance en développement Web et d'applications. Découvrez nos solutions innovantes pour une présence en ligne mémorable.",
  keywords:
    "G-COSMOS, développement web, applications, sites web, conception, technologie, digital, entreprise, Algérie, excellence numérique",
  author: "Hakim Kerrache",
};

export default Layout;
