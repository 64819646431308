import React from "react";
import Layout from "../components/Layout/Layout";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import ButtonGradient from "../assets/svg/ButtonGradient";
const PricingPage = () => {
  return (
    <Layout title={"Contact us"}>
      <Pricing />
      <Footer />
      <ButtonGradient />
    </Layout>
  );
};

export default PricingPage;
