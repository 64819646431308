import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";

import { uploadFile } from "../../components/firebase/UploadConfig";
import axiosInstance from "../../utils/axiosConfig";

const UserProfile = () => {
  const [auth, setAuth] = useAuth();
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [level, setLevel] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [tailleImage, setTailleImage] = useState("");
  const [stillSumbitting, setStillSubmitting] = useState("Enregistrer");
  const [numberOfChildren, setNumberOfChildren] = useState([]);

  //const [earning, setEarning] = useState(0);

  const { id } = useParams(); // Get the user ID from the URL parameter

  const formData = new FormData();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response1 = await axiosInstance.get(`/users/all`);
        const users = response1.data.users;
        const response = await axiosInstance.get(`/users/all/${id}`);
        const userData = response.data.user;
        setName(userData.name);
        setEmail(userData.email);
        setPhone(userData.phone);
        setAddress(userData.address);
        setLevel(userData.level);
        setWallet(userData.wallet);
        setReferralCode(userData.referralCode);
        setImage(userData.image);

        const referredUsers = users.filter(
          (user) => user.referralCode === userData._id
        );

        const numberOfChildren = referredUsers.length;
        setNumberOfChildren(numberOfChildren);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]); // Fetch data whenever the ID changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = image;

    if (newImage !== null) {
      const fileSizeInBytes = newImage.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      if (fileSizeInMB > 1) {
        setTailleImage(
          "La taille de l'image dépasse 1 MB. Veuillez choisir une image plus petite."
        );
        return;
      }
      imageUrl = await uploadFile(newImage);
      setImage(imageUrl);
    }

    formData.append("name", name);
    formData.append("image", imageUrl);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("level", level);
    formData.append("referralCode", referralCode);
    formData.append("wallet", wallet);
    formData.append("password", password);

    const contentType = formData.hasOwnProperty("image")
      ? "multipart/form-data"
      : "application/json";

    try {
      setStillSubmitting("Envoi encore ...");

      const { data } = await axiosInstance.put(`/users/all/${id}`, formData, {
        headers: {
          "Content-Type": contentType,
        },
      });

      if (data?.error) {
        toast.error(data?.error);
      } else {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        setStillSubmitting("Enregistrer");

        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Your Profile"}>
      <div className="main-container">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="table-container">
          <form className="user-form" onSubmit={handleSubmit}>
            <h4 className="title">Modifier l'Utilisateur</h4>

            <div>
              {image && (
                <img
                  src={image}
                  alt="User"
                  style={{
                    maxWidth: "200px",
                    borderRadius: "50%",
                    margin: "8px",
                  }}
                />
              )}

              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={(e) => setNewImage(e.target.files[0])}
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginTop: "8px",
                  cursor: "pointer",
                }}
              />
              <div>
                <p style={{ color: "red" }}>
                  {" "}
                  {tailleImage ? tailleImage : ""}{" "}
                </p>
                <p style={{ fontSize: "0.9rem" }}>
                  Note :La taille de l'image doit être inférieure à 1 MB.
                </p>
              </div>
            </div>

            <div className="mb-3">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="Entrez le Nom"
                autoFocus
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Entrez l'Email"
                disabled
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Entrez un Nouveau mot de Passe"
              />
            </div>
            <div className="mb-3">
              <h5>Numéro de Téléphone</h5>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                placeholder="Entrez le Téléphone"
              />
            </div>
            <div className="mb-3">
              <h5>Adresse</h5>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                placeholder="modifier l'adresse"
              />
            </div>
            <div className="mb-3">
              <h5>Niveau</h5>
              <input
                type="number"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                className="form-control"
                placeholder="modifier le Niveau"
              />
            </div>
            <div className="mb-3">
              <h5>Revenus (DA)</h5>
              <input
                type="number"
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
                className="form-control"
                placeholder="modifier les points"
              />
            </div>

            <div className="mb-3">
              <h5>Utilisateurs Référés: {numberOfChildren} </h5>
            </div>

            <div className="mb-3">
              <h5>Code référé:</h5>
              <input
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="form-control"
                placeholder="modifier le code de parrainage"
              />
            </div>
            <div>
              <h5>Code de Parrainage:</h5>
              <p
                style={{
                  backgroundColor: "#0e0e0e",
                  padding: "8px",
                  borderRadius: "6px",
                  color: "#fefefe",
                }}
              >
                {id}
              </p>
            </div>

            <button type="submit" className="btn btn-primary m-1">
              {stillSumbitting}
            </button>
            <Link to="/dashboard/admin/users" className="btn btn-secondary m-1">
              Retour
            </Link>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;

/*
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import axios from "axios";

const UserProfile = () => {
  const [auth, setAuth] = useAuth();
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [level, setLevel] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState([]);

  const { id } = useParams(); // Get the user ID from the URL parameter

  const formData = new FormData();
  const getFileInfo = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      formData.append("image", e.target.files[0]);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response1 = await axios.get(`/api/v1/users`);
        const users = response1.data.users;
        const response = await axios.get(`/api/v1/users/${id}`);
        const userData = response.data.user;
        setName(userData.name);
        setEmail(userData.email);
        setPhone(userData.phone);
        setAddress(userData.address);
        setLevel(userData.level);
        setWallet(userData.wallet);
        setReferralCode(userData.referralCode);
        setImage(userData.image);

        const referredUsers = users.filter(
          (user) => user.referralCode === userData._id
        );

        const numberOfChildren = referredUsers.length;
        setNumberOfChildren(numberOfChildren);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]); // Fetch data whenever the ID changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.append("name", name);
    //formData.append("image", image);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("level", level);
    formData.append("referralCode", referralCode);
    formData.append("wallet", wallet);
    formData.append("password", password);

    try {
      console.log(formData);
      const { data } = await axios.put(`/api/v1/users/${id}`, formData);

      if (data?.error) {
        toast.error(data?.error);
      } else {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Your Profile"}>
      <div className="main-container">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="table-container">
          <form className="user-form" onSubmit={handleSubmit}>
            <h4 className="title">Modifier l'Utilisateur</h4>

            <div className="mb-3">
              {image && (
                <div>
                  <h3>Photo de profil</h3>
                  <img
                    src={`/images/${image}`}
                    alt="User"
                    style={{
                      maxWidth: "150px",
                      borderRadius: "50%",
                      margin: "8px",
                    }}
                  />
                </div>
              )}

              <input
                type="file"
                onChange={getFileInfo}
                className="form-control"
                placeholder="Entrez le Nom"
                autoFocus
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="Entrez le Nom"
                autoFocus
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Entrez l'Email"
                disabled
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Entrez un Nouveau mot de Passe"
              />
            </div>
            <div className="mb-3">
              <h5>Numéro de Téléphone</h5>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                placeholder="Entrez le Téléphone"
              />
            </div>
            <div className="mb-3">
              <h5>Adresse</h5>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                placeholder="modifier l'adresse"
              />
            </div>
            <div className="mb-3">
              <h5>Niveau</h5>
              <input
                type="number"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                className="form-control"
                placeholder="modifier le Niveau"
              />
            </div>
            <div className="mb-3">
              <h5>Revenus (DA)</h5>
              <input
                type="number"
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
                className="form-control"
                placeholder="modifier les points"
              />
            </div>

            <div className="mb-3">
              <h5>Utilisateurs Référés: {numberOfChildren} </h5>
            </div>

            <div className="mb-3">
              <h5>Code référé:</h5>
              <input
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="form-control"
                placeholder="modifier le code de parrainage"
              />
            </div>
            <div>
              <h5>Code de Parrainage:</h5>
              <p
                style={{
                  backgroundColor: "#0e0e0e",
                  padding: "8px",
                  borderRadius: "6px",
                  color: "#fefefe",
                }}
              >
                {id}
              </p>
            </div>

            <button type="submit" className="btn btn-primary m-1">
              Enregistrer
            </button>
            <Link to="/dashboard/admin/users" className="btn btn-secondary m-1">
              Retour
            </Link>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
*/
