import React from "react";
import { NavLink } from "react-router-dom";

const AdminMenu = () => {
  return (
    <div className="admin-menu">
      <div className="dashboard-menu">
        <NavLink
          exact
          to="/dashboard/admin/"
          className="menu-item"
          activeClassName="active"
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/dashboard/admin/create-category"
          className="menu-item"
          activeClassName="active"
        >
          Categories
        </NavLink>
        <NavLink
          to="/dashboard/admin/create-product"
          className="menu-item"
          activeClassName="active"
        >
          Add Products
        </NavLink>
        <NavLink
          to="/dashboard/admin/products"
          className="menu-item"
          activeClassName="active"
        >
          Products
        </NavLink>
        <NavLink
          to="/dashboard/admin/orders"
          className="menu-item"
          activeClassName="active"
        >
          Orders
        </NavLink>
        <NavLink
          to="/dashboard/admin/users"
          className="menu-item"
          activeClassName="active"
        >
          Users
        </NavLink>
        <NavLink
          to="/dashboard/admin/withdrawel"
          className="menu-item"
          activeClassName="active"
        >
          Requests
        </NavLink>
      </div>
    </div>
  );
};

export default AdminMenu;
