import React from "react";
import Layout from "./../components/Layout/Layout";

const Policy = () => {
  return (
    <Layout title={"Privacy Policy"}>
      <div className="main-container">
        <div className="row contactus">
          <div className=" pages-img-div">
            <img
              src="/images/policy.png"
              alt="policy"
              style={{ width: "90%" }}
            />
          </div>
          <div className="pages-text-div">
            <h1>Politique de Confidentialité</h1>
            <p>
              Vos Informations: Nous protégeons vos données avec le plus grand
              soin.
            </p>
            <p>
              Collecte de Données: Nous collectons uniquement les informations
              nécessaires pour vous fournir nos services.
            </p>
            <p>
              Utilisation des Données: Vos données sont utilisées uniquement
              dans le but de vous offrir une expérience personnalisée sur notre
              site.
            </p>
            <p>
              Sécurité: Nous mettons en place des mesures de sécurité robustes
              pour protéger vos informations contre tout accès non autorisé.
            </p>
            <p>
              Partage d'Informations: Nous ne partageons jamais vos données
              personnelles avec des tiers sans votre consentement.
            </p>
            <p>
              Consentement: En utilisant notre site, vous consentez à notre
              politique de confidentialité et à l'utilisation de vos données
              telles que décrites.
            </p>
            <p>
              Cookies: Nous utilisons des cookies pour améliorer votre
              expérience de navigation sur notre site.
            </p>
            <p>
              Modifications: Nous nous réservons le droit de modifier notre
              politique de confidentialité à tout moment.
            </p>
            <p>
              Accès et Contrôle: Vous avez le droit d'accéder à vos données
              personnelles et de demander leur correction ou leur suppression si
              nécessaire.
            </p>
            <p>
              Contactez-nous: Si vous avez des questions concernant notre
              politique de confidentialité, n'hésitez pas à nous contacter.
            </p>
          </div>
          <hr className="mb-5" />
        </div>
      </div>
    </Layout>
  );
};

export default Policy;
