import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { background } from "../assets/index";
import { exclam } from "../assets/index";
//import { useAuth } from "../context/auth";

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(3);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 &&
      navigate(`/${path}`, {
        state: location.pathname,
      });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          backgroundImage: background,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="home-card p-5 d-flex flex-column justify-content-center align-items-center">
          <img src={exclam} className="w-[180px]" />
          <h1 className="Text-center text-xl mb-5">G-COSMOS WEB</h1>
          <p>Back to Home page in {count} second</p>
          <div className="spinner-border" role="status">
            <span className="visually-hidden"> Loading... </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Spinner;

/*
//new version
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { background } from "../assets/index";
import { exclam } from "../assets/index";
import { useAuth } from "../context/auth";

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(3);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 &&
      setAuth({
        ...auth,
        user: null,
        token: "",
      });
    localStorage.removeItem("auth");
    toast.success("Logout Successful");
    navigate(`/${path}`, {
      state: location.pathname,
    });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          backgroundImage: background,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="home-card p-5 d-flex flex-column justify-content-center align-items-center">
          <img src={exclam} className="w-[180px]" />
          <h1 className="Text-center text-xl mb-5">G-COSMOS WEB</h1>
          <p>Back to Home page in {count} second</p>
          <div className="spinner-border" role="status">
            <span className="visually-hidden"> Loading... </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
*/
