import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "./../../components/Layout/Layout";

import { useAuth } from "../../context/auth";
import moment from "moment";

import axiosInstance from "../../utils/axiosConfig";

const Retraits = () => {
  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();

  const [id] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { _id } = auth?.user || {};
    };
    fetchData();
  }, [auth?.user]);

  const getOrders = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/withdrawal/getAllWithdrawalsByUserId/${id}`
      );
      setOrders(data);
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getOrders();
    }
  }, [id]);

  return (
    <Layout title={"Your Orders"}>
      <div className="main-container">
        <div className="col-md-3">
          <UserMenu />
        </div>
        <div className="table-container">
          <h1 className="text-center">Mes demandes</h1>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Description</th>
                <th scope="col">Type</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((o, i) => (
                <tr key={o._id}>
                  <td>{i + 1}</td>
                  <td>{o?.status}</td>
                  <td>{moment(o?.createdAt).fromNow()}</td>
                  <td>{o?.description}</td>
                  <td>{o?.requestType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Retraits;
