import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { Select } from "antd";
import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";
const { Option } = Select;

const WithDrawals = () => {
  const [status] = useState(["En attente", "approuvé", "rejeté"]);

  const [orders, setOrders] = useState([]);
  const [auth] = useAuth();
  const getOrders = async () => {
    try {
      const { data } = await axiosInstance.get("/withdrawal/getallwithdrawals");
      setOrders(data);
      //console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  const handleStatusChange = async (id, value) => {
    try {
      if (value === "approuvé") {
        //console.log("oui paprrove");
        await axiosInstance.put(`/withdrawal/updateStatus/${id}`, {
          action: "approve",
        });
        toast.success("Commande approuvé avec succès");
        getOrders();
      } else if (value === "rejeté") {
        await axiosInstance.put(`/withdrawal/updateStatus/${id}`, {
          action: "reject",
        });
        toast.success("Commande rejeté avec succès");
        getOrders();
      } else if (value === "En attente") {
        await axiosInstance.put(`/withdrawal/updateStatus/${id}`, {
          action: "En attente",
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      const confirmPayment = window.confirm(
        "Êtes-vous sûr de vouloir supprimer la demande de retrait ?"
      );
      if (!confirmPayment) {
        return;
      }
      await axiosInstance.delete(`/withdrawal/deletewithdrawals/${id}`);
      //  /deletewithdrawals/:id
      toast.success("Demande de retrait supprimé avec succès");
      getOrders();
    } catch (error) {
      console.log(error);
      toast.error("Échec de la suppression de la demande retrait");
    }
  };

  return (
    <Layout title={"Demande de retrait"}>
      <div className="main-container">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="table-container">
          <h1 className="text-center">Toutes les demandes de retrait </h1>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Montant demandé</th>
                <th scope="col">Description</th>
                <th scope="col">Utilisateur</th>
                <th scope="col">Montant actuel</th>
                <th scope="col">Méthode de paiement</th>
                <th scope="col">Réference de paiement </th>
                <th scope="col">Statut</th>
                <th scope="col">Date de création</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((o, i) => (
                <tr key={o._id}>
                  <td>{i + 1}</td>
                  <td>{Math.floor(o.amount * 0.95)}</td>
                  <td>{o.description}</td>
                  <td>{o.user.name}</td>
                  <td>{` ${o.user.earning - o.user.withdrawals}`}</td>
                  <td>{o.paymentMethod}</td>
                  <td>
                    {" "}
                    {o?.ccpAccountNumber ??
                      o?.paypalEmail ??
                      o?.cardNumber ??
                      o?.baridMobRIP ??
                      "Aucune référence indiquer"}{" "}
                  </td>

                  <td>
                    <Select
                      bordered={false}
                      onChange={(value) => handleStatusChange(o._id, value)}
                      defaultValue={o?.status}
                    >
                      {status.map((s, i) => (
                        <Option
                          key={i}
                          value={s}
                          disabled={o.status !== "En attente"}
                        >
                          {s}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <td>{moment(o?.createdAt).fromNow()}</td>
                  <td>
                    <Button
                      className=""
                      onClick={() => handleDeleteOrder(o._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default WithDrawals;
