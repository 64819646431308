import React from "react";
import Layout from "./../components/Layout/Layout";
import { useSearch } from "../context/search";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useCart } from "../context/cart";
import Section from "../components/Section";
import SearchInput from "../components/Form/SearchInput";
import Footer from "../components/Footer";
import Button from "../components/Button";
import "../styles/Homepage.css";

const Search = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [values, setValues] = useSearch();
  return (
    <Layout title={"Search results"}>
      <Section crosses>
        <div className="container ">
          <SearchInput />
        </div>
        <div className="">
          <div className="text-center p-5">
            <h6>
              {values?.results.length < 1
                ? "No Products Found"
                : `${values?.results.length} 📱 Products Found`}
            </h6>
            <div className="d-flex flex-wrap mt-4">
              {values?.results.map((p) => (
                <div className="search-product-card w-[260px] m-2">
                  <div
                    className="product-img w-[230px] lg:w-[250px] h-[200px]"
                    style={{
                      backgroundImage: `url(https://gcosmosweb-server.vercel.app/api/v1/product/product-photo/${p._id})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <p className="product-name">{p.name}</p>
                  </div>

                  <div className="card-body">
                    <p className="card-text">
                      {p.description.substring(0, 80)}...
                    </p>
                    <p className="card-product-price">{p.price} DA</p>
                    <Button
                      white
                      className="max-lg:p-3 ml-2 mb-3"
                      onClick={() => navigate(`/product/${p.slug}`)}
                    >
                      See More 🔎
                    </Button>
                    <Button
                      className="max-lg:p-3 ml-2"
                      onClick={() => {
                        setCart([...cart, p]);
                        localStorage.setItem(
                          "cart",
                          JSON.stringify([...cart, p])
                        );
                        toast.success("Produit ajouté au panier");
                      }}
                    >
                      Add 🛒
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </Layout>
  );
};

export default Search;
