import Section from "./Section";
import { pricingImg, stars } from "../assets";
import Heading from "./Heading";
import PricingList from "./PricingList";
import { LeftLine, RightLine } from "./design/Pricing";
import quote from "../assets/quote.svg";
import { NavLink } from "react-router-dom";

const Pricing = () => {
  return (
    <Section className="overflow-hidden" id="pricing">
      <div className="container relative z-2 pt-2">
        <div className="hidden relative justify-center mb-[6.5rem] lg:flex">
          <img
            src={pricingImg}
            className="relative z-1"
            width={255}
            height={255}
            alt="Sphere"
          />
          <div className="absolute top-1/2 left-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            <img
              src={stars}
              className="w-full"
              width={950}
              height={400}
              alt="Stars"
            />
          </div>
        </div>

        <Heading
          tag="Get started with G-COSMOS WEB"
          title="Pay once, use forever"
        />

        <div className="relative">
          <PricingList />
          <LeftLine />
          <RightLine />
        </div>

        <div className="flex justify-center mt-10">
          <img src={quote} width={24} height={24} alt="Check" />
          <NavLink to="/contact">
            <a className="text-xl font-code font-bold tracking-wider uppercase border-b ml-4">
              Get a Quote
            </a>
          </NavLink>
        </div>
      </div>
    </Section>
  );
};

export default Pricing;
