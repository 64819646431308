import React, { useState, useEffect } from "react";

import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "./../../components/Layout/Layout";

import axiosInstance from "../../utils/axiosConfig";
import Button from "../../components/Button";
//import SearchUsers from "../../components/Form/SearchUsers";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const usersPerPage = 10;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get(
          `/users?page=${currentPage}&limit=${usersPerPage}`
        );
        const { totalUsers, users } = response.data;

        // Fetch orders and calculate total order value for each user
        const usersWithOrders = await Promise.all(
          users.map(async (user) => {
            const ordersResponse = await axiosInstance.get(
              `/auth/orders/${user._id}`
            );
            const orders = ordersResponse.data;

            // Calculate total order value for each order of the user
            const totalOrderValue = await Promise.all(
              orders.map(async (order) => {
                // Fetch products for the order
                const productsResponse = await Promise.all(
                  order.products.map(async (productId) => {
                    try {
                      const productResponse = await axiosInstance.get(
                        `/products/${productId}`
                      );
                      const product = productResponse.data.product;
                      const productPrice = product?.price || 0;
                      return productPrice;
                    } catch (error) {
                      console.error(
                        `Error fetching product with ID ${productId}:`,
                        error
                      );
                      return 0; // Return 0 if there's an error fetching the product
                    }
                  })
                );

                // Calculate total price for the order
                const orderTotalPrice = productsResponse.reduce(
                  (sum, price) => sum + price,
                  0
                );

                return orderTotalPrice;
              })
            );

            // Calculate total order value for the user
            const userTotalOrderValue = totalOrderValue.reduce(
              (sum, totalPrice) => sum + totalPrice,
              0
            );

            return { ...user, totalOrderValue: userTotalOrderValue };
          })
        );

        const calculatedTotalPages = Math.ceil(totalUsers / usersPerPage);

        setTotalPages(calculatedTotalPages);

        setUsers(usersWithOrders);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [currentPage]);

  const handleEdit = (id) => {
    window.location.href = `/dashboard/admin/userProfile/${id}`;
  };

  const handleDelete = async (id) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")
    ) {
      try {
        await axiosInstance.delete(`/users/${id}`);
        setUsers(users.filter((user) => user._id !== id));
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  // Logic to get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Layout title={"Dashboard - All Users"}>
      <div className="main-container">
        <AdminMenu />

        <div className="table-container">
          <h1>Tous les Utilisateurs</h1>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Adresse</th>
                <th>ID</th>
                <th>Niveau</th>
                <th>Revenus</th>

                <th>Total Commandes</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user._id}>
                  <td>{i + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.address}</td>
                  <td>{user._id}</td>
                  <td>{user.level}</td>
                  <td>{user.wallet}</td>

                  <td>{user.totalOrderValue}</td>

                  <td>
                    <Button
                      white
                      className="btn"
                      onClick={() => handleEdit(user._id)}
                    >
                      Modifier
                    </Button>
                    <Button
                      className="btn"
                      onClick={() => handleDelete(user._id)}
                    >
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className="pagination">
            {Array.from({ length: totalPages }).map((_, index) => (
              <li key={index} className="page-item">
                <button
                  onClick={() => paginate(index + 1)}
                  className="page-link"
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Users;
