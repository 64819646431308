import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requires carousel CSS
import { Carousel } from "react-responsive-carousel";
import Section from "./Section";
import Heading from "./Heading";
import { NavLink } from "react-router-dom";

import Button from "./Button";

const HomeProducts = () => {
  const [categories, setCategories] = useState([]);


  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all categories
  const getAllCategory = async () => {
    try {
      const { data } = await axiosInstance.get("/category/get-category");
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch products by category
  const getProductsByCat = async (slug) => {
    try {
      const { data } = await axiosInstance.get(
        `/product/product-category/${slug}`
      );
      if (data?.success) {
        setProducts((prev) => ({ ...prev, [slug]: data.products }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      categories.forEach((category) => {
        getProductsByCat(category.slug);
      });
    }
    setLoading(false);
  }, [categories]);

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Section crosses id="homeProducts">
      <div className="container">
        <Heading
          title="Our Products"
          text="Here are the products sorted by category."
        />
        <div className="home-cat-div flex center">
          {categories.map((category) => (
            <div key={category._id} className="home-cat-card ">
              <NavLink to="/categories">
                <Button className="mb-2 text-xl">{category.name}</Button>
              </NavLink>

              {products[category.slug] && products[category.slug].length > 0 ? (
                <Carousel
                  showArrows={true}
                  autoPlay={true}
                  interval={2000}
                  infiniteLoop={true}
                >
                  {products[category.slug].map((product) => (
                    <div key={product._id} className="home-product-card">
                      <img
                        className="w-full h-auto rounded-md"
                        src={`data:${
                          product.photo.contentType
                        };base64,${arrayBufferToBase64(
                          product.photo.data.data
                        )}`}
                        alt={product.name}
                      />
                      <p className="legend">{product.name}</p>
                    </div>
                  ))}
                </Carousel>
              ) : (
                <p>No products available for this category.</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default HomeProducts;
