import React, { useState } from "react";

import toast from "react-hot-toast";
import Button from "../Button";
import axiosInstance from "../../utils/axiosConfig";

const ContactForm = () => {
  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    agreeToTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreeToTerms) {
      toast.error("You must agree to the terms of use");
      return;
    }
    setIsSending(true);
    try {
      const response = await axiosInstance.post("/email/send-email", formData);
      if (response.status === 200) {
        toast.success("Email sent successfully");
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
          agreeToTerms: false,
        });
      } else {
        toast.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send email");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="form-group">
          <label className="block mb-2 text-sm font-medium">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
            required
          />
        </div>
        <div className="form-group">
          <label className="block mb-2 text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
            required
          />
        </div>
        <div className="form-group">
          <label className="block mb-2 text-sm font-medium">Subject</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="form-group">
          <label className="block mb-2 text-sm font-medium">Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
            rows="4"
            required
          ></textarea>
        </div>
        <div className="form-group flex items-center">
          <input
            type="checkbox"
            name="agreeToTerms"
            checked={formData.agreeToTerms}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label className="text-sm">I agree to the terms of use</label>
        </div>
        <Button type="submit" className="px-4 py-2" disabled={isSending}>
          {isSending ? "Sending...📩" : "Send Message 📧"}
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
