import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";

import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import { StarsCanvas } from "../../components/canvas";
import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [answer, setAnswer] = useState("");
  const [role, setRole] = useState(""); // State for role

  const navigate = useNavigate();

  // Function to send registration email
  const sendRegistrationEmail = async (name, email, answer, role) => {
    try {
      await axiosInstance.post("/email/register-email", {
        name,
        email,
        answer,
        role,
      });
      toast.success("Registration email sent successfully");
    } catch (error) {
      console.error("Error sending registration email:", error);
      toast.error("Failed to send registration email");
    }
  };

  // Form submission function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("/auth/register", {
        name,
        email,
        password,
        phone,
        address,
        answer,
        role,
      });
      if (res && res.data.success) {
        toast.success(res.data.message);
        // Send registration email after successful registration
        sendRegistrationEmail(name, email, answer, role);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Options for the role dropdown
  const roleOptions = [
    { label: "Business", value: "1" },
    { label: "Digital Creator", value: "2" },
    { label: "Client", value: "4" },
  ];

  return (
    <Layout title="Register - G-Cosmos Web">
      <div
        className="form-container"
        style={{
          minHeight: "100vh",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h4 className="title mb-5">Create your Account</h4>
          <div className="mb-3">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Name"
              required
              autoFocus
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Password"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              placeholder="Phone number"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="form-control"
              placeholder="Address"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="form-control"
              placeholder="Your secret word"
              required
            />
          </div>
          <div className="mb-3">
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="form-select"
              required
            >
              <option value="">Choose your role</option>
              {roleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="btns-form-div">
            <div>
              <Button type="submit" className="flex mt-3 mb-3 ">
                Register
              </Button>
            </div>
            <div className="switch-link">
              <Link to="/login">Login here 👉</Link>
            </div>
          </div>
        </form>
      </div>
      <StarsCanvas />
    </Layout>
  );
};

export default Register;

/*

import React, { useState } from "react";
import Layout from "./../../components/Layout/Layout";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import { StarsCanvas } from "../../components/canvas";
import Button from "../../components/Button";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [answer, setAnswer] = useState("");
  const [role, setRole] = useState(""); // State for role

  const navigate = useNavigate();

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/register", {
        name,
        email,
        password,
        phone,
        address,
        answer,
        role,
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Options for the role dropdown
  const roleOptions = [
    { label: "Business", value: "1" },
    { label: "Client", value: "4" },
  ];

  return (
    <Layout title="Register - G-Cosmos Web">
      <div
        className="form-container"
        style={{
          minHeight: "100vh",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h4 className="title mb-5">Create your Account</h4>
          <div className="mb-3">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="Name"
              required
              autoFocus
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Password"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              placeholder="Phone number"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="form-control"
              placeholder="Address"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="form-control"
              placeholder="Your secret word"
              required
            />
          </div>
          <div className="mb-3">
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="form-select"
              required
            >
              <option value="">Choose your role</option>
              {roleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="btns-form-div">
            <div>
              <Button type="submit" className="flex mt-3 mb-3 ">
                Register
              </Button>
            </div>
            <div className="switch-link">
              <Link to="/login">Login here 👉</Link>
            </div>
          </div>
        </form>
      </div>
      <StarsCanvas />
    </Layout>
  );
};

export default Register;
*/
