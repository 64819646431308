import { storage, storageRef } from "./Config";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export const uploadFile = async (image) => {
  const newUuid = uuidv4();
  const imageRef = storageRef(storage, `user${newUuid}`);

  try {
    const snapshot = await uploadBytes(imageRef, image);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};
