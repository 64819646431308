import React from "react";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

import UserMenu from "../../components/Layout/UserMenu";
const SuplierDashboard = () => {
  const [auth] = useAuth();

  return (
    <Layout>
      <div className="main-container">
        <div className="col-md-3">
          <UserMenu />
        </div>
        <div className="table-container">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{auth?.user?.name}</td>
              </tr>
              <tr>
                <td>Account Type</td>
                <td>Client</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{auth?.user?.email}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{auth?.user?.address}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{auth?.user?.phone}</td>
              </tr>
              <tr>
                <td>wallet</td>
                <td>{auth?.user?.wallet} DA</td>
              </tr>
              <tr>
                <td>Your ID</td>

                <td>{auth?.user?._id}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default SuplierDashboard;
