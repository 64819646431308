import React, { useState } from "react";
import Layout from "./../../components/Layout/Layout";

import { useNavigate, useLocation, Link } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import { useAuth } from "../../context/auth";
import { StarsCanvas } from "../../components/canvas";

import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // login form function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axiosInstance.post("/auth/login", {
        email,
        password,
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));

        setTimeout(() => {
          navigate(location.state || "/");
        }, 1500);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const getDashboardRoute = (role) => {
    switch (role) {
      case 940:
        return "admin";
      case 1:
        return "business";
      case 2:
        return "digitalCreator";
      case 3:
        return "manager";
      case 4:
        return "client";
      default:
        return "user";
    }
  };
  return (
    <Layout title="Login to G-Cosmos web">
      <div className="relative ">
        <div
          className="form-container"
          style={{
            minHeight: "100vh",
          }}
        >
          <div className="mt-5">
            <form className="login-form" onSubmit={handleSubmit}>
              <div className=" mb-5">
                <h4 className="title">Login</h4>
              </div>

              <div className="mb-3">
                <input
                  type="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="email "
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="password"
                  required
                />
              </div>

              <div className="btns-form-div">
                <div>
                  <Button type="submit" className="flex mt-3 mb-2">
                    Connexion
                  </Button>
                </div>
                <div className="switch-link">
                  <Link to="/register">Create a new Account 👉</Link>
                </div>
              </div>

              <div className="mt-3">
                <Button
                  type="button"
                  className="mt-3"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  white
                >
                  Forgot your password!🤦‍♂️
                </Button>
              </div>
            </form>
          </div>
        </div>
        <StarsCanvas />
      </div>
    </Layout>
  );
};

export default Login;
