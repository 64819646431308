import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";

import toast from "react-hot-toast";
import { useCart } from "../context/cart";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import Section from "../components/Section";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { FaShareAlt } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import axiosInstance from "../utils/axiosConfig";
import ButtonGradient from "../assets/svg/ButtonGradient";

const ProductDetails = () => {
  const [cart, setCart] = useCart();
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);

  // Initial details
  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);

  // Get product details
  const getProduct = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      console.log(data?.product);
      if (data?.product?.category) {
        getSimilarProduct(data?.product._id, data?.product.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get similar products
  const getSimilarProduct = async (pid, cid) => {
    try {
      const { data } = await axiosInstance.get(
        `/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
      //console.log(`Here are the related products ${data}`);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle back button click
  const handleBack = () => {
    navigate(-1);
  };

  // Handle share button click
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link copied successfully");
    } catch (error) {
      toast.error("Failed to copy the link");
    }
  };

  return (
    <Layout title={`Details: ${product.name}`}>
      <Section crosses>
        <div className="container pro-nav-bar max-lg:mt-[80px]">
          <Button className="text-[22px] p-3" onClick={handleBack}>
            <TiArrowBack />
          </Button>
          <Button white className="text-[20px] p-3" onClick={handleShare}>
            <FaShareAlt />
          </Button>
        </div>
        <div className="row container product-details">
          <div className="col-md-6 mb-3">
            <div
              className="card-img-top "
              style={{
                backgroundImage: `url(https://gcosmosweb-server.vercel.app/api/v1/product/product-photo/${product._id})`,
              }}
            >
              <p>GCW</p>
            </div>
          </div>
          <div className="col-md-6 product-details-info">
            <div className="product-details-div">
              <h1 className="text-center">Product Details</h1>
              <hr />
              <h6 className="mt-2">{product.name}</h6>
              <h6 className="text-[#afafaf]">
                Description : {product.description}
              </h6>
              <h6 className="font-bold text-[#00fb04]">
                Price :
                {product?.price?.toLocaleString("fr-DZ", {
                  style: "currency",
                  currency: "DZD",
                })}
              </h6>
            </div>

            <Button
              className="mb-3 text-[18px]"
              onClick={() => {
                setCart([...cart, product]);
                localStorage.setItem(
                  "cart",
                  JSON.stringify([...cart, product])
                );
                toast.success("Product added to cart");
              }}
            >
              Add to Cart 🛒
            </Button>
            <Button
              className="mb-5 text-[18px]"
              onClick={() => {
                setCart([...cart, product]);
                localStorage.setItem(
                  "cart",
                  JSON.stringify([...cart, product])
                );
                toast.success("Product added to cart");
                navigate(`/cart`);
              }}
            >
              Buy 💸
            </Button>
          </div>
        </div>
        <Section crosses>
          <div className="row container similar-products">
            <h4>Similar Products</h4>
            {relatedProducts.length < 1 && (
              <p className="text-center">No Similar Products found</p>
            )}
            <div className="d-flex flex-wrap">
              {relatedProducts?.map((p) => (
                <div className="card m-2" key={p._id}>
                  <div
                    className="product-img"
                    style={{
                      backgroundImage: `url(https://gcosmosweb-server.vercel.app/api/v1/product/product-photo/${p._id})`,
                    }}
                  >
                    <p className="product-name">{p.name}</p>
                  </div>
                  <div className="card-body">
                    <div className="card-name-price">
                      <h5 className="card-title font-bold text-[#00fb04]">
                        {p.price} DA
                      </h5>
                    </div>
                    <p className="text-[12px] text-[#afafaf] ">
                      {p.description.substring(0, 60)}...
                    </p>
                    <div className="card-name-price">
                      <Button
                        white
                        className="mr-3"
                        onClick={() => navigate(`/product/${p.slug}`)}
                      >
                        See More
                      </Button>
                      <Button
                        className="text-[18px]"
                        onClick={() => {
                          setCart([...cart, p]);
                          localStorage.setItem(
                            "cart",
                            JSON.stringify([...cart, p])
                          );
                          toast.success("Produit ajouté au panier");
                        }}
                      >
                        Add🛒
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Section>
      </Section>
      <ButtonGradient />
      <Footer />
    </Layout>
  );
};

export default ProductDetails;
