import React from "react";
import { Link } from "react-router-dom";
import useCategory from "../hooks/useCategory";
import Layout from "../components/Layout/Layout";
import "../styles/CartStyles.css";

import Section from "../components/Section";
import Footer from "../components/Footer";
import { catBg } from "../assets/index";
import { StarsCanvas } from "../components/canvas";
import ClipPath from "../assets/svg/ClipPath";

import ButtonGradient from "../assets/svg/ButtonGradient";

const Categories = () => {
  const categories = useCategory();
  return (
    <Layout title={"All Categories"}>
      <Section crosses>
        <div className="max-lg:mt-[80px]">
          <div className="row container relative">
            {categories.map((c) => (
              <div
                className="col-md-4 mt-2 mb-3 gx-3 gy-3 cat-card"
                key={c._id}
              >
                <Link to={`/category/${c.slug}`} className="cat-link">
                  {c.name}
                </Link>
                <div
                  className="absolute inset-0.5 bg-n-8/50"
                  style={{ clipPath: "url(#benefits)" }}
                >
                  <div className="absolute inset-0 opacity-5 transition-opacity hover:opacity-15">
                    <img
                      src={catBg}
                      width={250}
                      height={150}
                      alt="cat"
                      className="w-full h-full object-cover  "
                    />
                  </div>
                </div>
                <ClipPath />
              </div>
            ))}
          </div>
        </div>
      </Section>

      <StarsCanvas />
      <Footer />
      <ButtonGradient />
    </Layout>
  );
};

export default Categories;
